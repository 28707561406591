<style lang="scss"></style>
<template>
  <div :class="$vuetify.breakpoint.smAndDown ? '' : 'padding-desktop-promotion'" style="background-color:white;">
    <!--      mobile filter header-->
    <v-row class="hidden-md-and-up" style="margin:0;">
      <v-col cols="12" class="mobile-header text-center">
        {{ $t(`label.mobileHeader_Promotion`) }}
      </v-col>
    </v-row>
    <v-card class="hidden-md-and-up">
      <v-row justify="space-around" no-gutters>
        <v-col cols="12">
          <!--          <v-btn @click="getCmsBonus(0)"-->
          <!--                 class="pt-2 pb-1 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed>-->
          <!--            <v-row no-gutters>-->
          <!--              <v-col cols="12" class="py-0 text-center">-->
          <!--                <v-divider v-show="searchCriteria.bonusCategoryId == 0"-->
          <!--                           class="bonus-category-menu-divider mx-2"></v-divider>-->
          <!--              </v-col>-->
          <!--              <v-col cols="12" class="px-0 pb-1 text-center">-->
          <!--                <label class="bonus-category-menu-title px-2 font-weight-bold">{{ $t(`label.all`) }}</label>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-btn>-->
          <!--          <v-btn v-for="bonusCategory in translatedBonusCategories" :key="bonusCategory.id"-->
          <!--                 @click="getCmsBonus(bonusCategory.id)" class="pt-2 pb-1 px-0 page-navigator-button" height="auto" text-->
          <!--                 :ripple="false" light depressed>-->
          <!--            <v-row no-gutters>-->
          <!--              <v-col cols="12" class="py-0 text-center">-->
          <!--                <v-divider v-show="searchCriteria.bonusCategoryId == bonusCategory.id"-->
          <!--                           class="bonus-category-menu-divider mx-2"></v-divider>-->
          <!--              </v-col>-->
          <!--              <v-col cols="12" class="px-0 pb-1 text-center">-->
          <!--                <label class="bonus-category-menu-title px-2 font-weight-bold">{{ bonusCategory.name }}</label>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-btn>-->
          <v-menu bottom :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-card class="mobile-menu-card-wrapper">
                <v-btn v-bind="attrs" v-on="on" block class="mobile-category-menuBtn">
                  {{ selectedPromoCategory }}
                  <v-icon right large>mdi-menu-down</v-icon>
                </v-btn>
              </v-card>
            </template>
            <v-list>
              <v-list-item @click="getCmsBonus(0)">
                {{ $t(`label.all`) }}
              </v-list-item>
              <v-list-item v-for="bonusCategory in translatedBonusCategories" :key="bonusCategory.id" @click="getCmsBonus(bonusCategory.id, bonusCategory.name)">
                {{ bonusCategory.name }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>

    <!--      desktop filter header-->
    <v-card class="bonus-category-menu justify-space-around hidden-sm-and-down mb-2" style="box-shadow: none;">
      <!--      <v-row justify="space-around" no-gutters>-->
      <v-row class="promotion-menu px-2" no-gutters>
        <v-btn
          @click="getCmsBonus(0)"
          class="my-2 px-3 py-2 page-promotion-button"
          height="auto"
          text
          :ripple="false"
          light
          depressed
          :class="{ 'conditional-styles': searchCriteria.bonusCategoryId == 0 }"
        >
          <!--        <v-btnclass="bonus-category-men @click="getCmsBonus(0)" class="my-1 px-0 page-promotion-button" height="auto" text :ripple="false" light depressed>-->
          <v-row no-gutters>
            <!--            <v-col cols="12" class="py-0 text-center">-->
            <!--              <v-divider v-show="searchCriteria.bonusCategoryId == 0" class="bonus-category-menu-divider mx-2"></v-divider>-->
            <!--            </v-col>-->
            <v-col cols="12" class="px-0 text-center">
              <label class="bonus-category-menu-title px-2">{{ $t(`label.all`) }}</label>
            </v-col>
          </v-row>
        </v-btn>
        <v-btn
          v-for="bonusCategory in translatedBonusCategories"
          :key="bonusCategory.id"
          @click="getCmsBonus(bonusCategory.id)"
          class="my-2 px-3 py-2 page-promotion-button"
          :class="{ 'conditional-styles': searchCriteria.bonusCategoryId === bonusCategory.id }"
          height="auto"
          text
          :ripple="false"
          light
          depressed
        >
          <v-row no-gutters>
            <!--            <v-col cols="12" class="py-0 text-center">-->
            <!--              <v-divider v-show="searchCriteria.bonusCategoryId == bonusCategory.id" class="bonus-category-menu-divider mx-2"></v-divider>-->
            <!--            </v-col>-->
            <v-col cols="12" class="px-0 text-center">
              <label class="bonus-category-menu-title px-2">{{ bonusCategory.name }}</label>
            </v-col>
          </v-row>
        </v-btn>
      </v-row>
    </v-card>

    <!--    desktop n mobile share responsive layout-->
    <v-row class="d-flex mt-0 pt-0" justify="space-around" align="end">
      <v-col md="12" class="" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-0 pr-0 pt-0'">
        <v-card class="promotion-card-container" style="box-shadow: none;">
          <v-card-text>
            <v-row align="center">
              <v-col v-for="(bonus, index) in bonusContent" :key="index" cols="12" md="6" lg="6" class="pa-3">
                <v-card :class="$vuetify.breakpoint.mdAndUp ? 'promotion-box pa-6' : 'promotion-box'" style="box-shadow: none;">
                  <!--                <v-card class="promotion-box pa-6" style="box-shadow: none;">-->
                  <v-img class="promotion-image-box hidden-md-and-down" :src="`${bonus.mobile_img}`" :aspect-ratio="588 / 218" contain></v-img>
                  <v-img class="promotion-image-box hidden-lg-and-up" :src="`${bonus.mobile_img}`" :aspect-ratio="588 / 218" contain></v-img>
                  <v-row no-gutters class="promotion-box-row">
                    <v-col class="col-12">
                      <v-row v-if="$vuetify.breakpoint.xsOnly" class="promotion-desc-box" no-gutters>
                        <v-col cols="12">
                          <p class="font-weight-bold promotion-desc-title" :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-1'">{{ bonus.title }}</p>
                          <p class="d-block text-truncate promotion-menu-desc" :class="$vuetify.breakpoint.mdAndUp ? 'mb-3' : 'mb-1'">{{ bonus.desc }}</p>
                        </v-col>
                        <v-col cols="6" md="3" style="align-self: flex-end;padding-right:5px;">
                          <v-btn @click="openPromotionTnc(bonus)" height="auto" dark class="primary-button pa-1 font-weight-bold white--text mobile-detail-btn">{{ $t(`button.readMore`) }}</v-btn>
                        </v-col>
                        <v-col cols="6" md="3" style="align-self: flex-end;padding-left:5px;" v-if="bonus.has_apply_button">
                          <v-btn
                            height="auto"
                            class="full-width font-weight-bold pa-1 buttonPrimary primary-button theme--light mobile-apply-btn"
                            @click="onApplyBonusClick(bonus)"
                            :disabled="isDisableBonusButton"
                          >
                            {{ $t(`label.claimNow`) }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else class="promotion-desc-box" no-gutters>
                        <v-col cols="12">
                          <p class="mb-4 font-weight-bold promotion-desc-title">{{ bonus.title }}</p>
                          <p class="mb-3 d-flex align-center" style="font-family: 'Heebo-Regular', sans-serif; font-size: 14px; height: 44px" v-truncate="2">
                            {{ bonus.desc }}
                          </p>
                        </v-col>
                        <v-col cols="6" md="3" style="align-self: flex-end;padding-right:10px;">
                          <v-btn @click="openPromotionTnc(bonus)" dark class="primary-button pa-2 white--text mobile-detail-btn" :class="$vuetify.breakpoint.mdAndUp ? '' : 'font-weight-bold'">
                            {{ $t(`button.readMore`) }}
                          </v-btn>
                        </v-col>
                        <v-col cols="6" md="3" style="align-self: flex-end;padding-left:10px;" v-if="bonus.has_apply_button">
                          <v-btn
                            class="full-width pa-2 buttonPrimary primary-button theme--light mobile-apply-btn"
                            :class="$vuetify.breakpoint.mdAndUp ? '' : 'font-weight-bold'"
                            @click="onApplyBonusClick(bonus)"
                            :disabled="isDisableBonusButton"
                          >
                            {{ $t(`label.claimNow`) }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="text-center" v-if="bonusContent.length <= 0" cols="12">
                <p class="text-center text-capitalize mb-0">{{ $t(`label.noPromotionAvaliable`) }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <app-dialog v-if="$vuetify.breakpoint.mdAndDown" :dialogShow="promotionDialog.show" :max-width="1100" :title="promotionDialog.title" :closeAction="promotionDialog.dialogXButton">
      <v-container :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <div class="cms_bonus_content text-left" style="width:100%">
          <v-row no-gutters>
            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly && selectedBonusObj && selectedBonusObj.has_apply_button">
              <v-btn
                v-if="isAllowApply"
                class="mb-4 full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light"
                style="width:100%"
                @click="onApplyBonusClick(selectedBonusObj)"
                :disabled="isDisableBonusButton"
              >
                {{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="10" lg="10" sm="10">
              <p class="font-weight-bold mb-0">{{ $t(`label.promotionPeriod`) }}</p>
              <p>{{ promoData.start_time | utcToLocalTime }} - {{ promoData.end_time | utcToLocalTime }}</p>
            </v-col>
            <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp && selectedBonusObj && selectedBonusObj.has_apply_button">
              <v-btn class="full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light" @click="onApplyBonusClick(selectedBonusObj)" :disabled="isDisableBonusButton">
                {{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" v-html="promoHtml" class="cms_bonus_content text-left"></v-col>
          </v-row>
        </div>
      </v-container>
    </app-dialog>

    <newapp-dialog v-if="$vuetify.breakpoint.lgAndUp" :dialogShow="promotionDialog.show" :max-width="1100" :closeAction="promotionDialog.dialogXButton" :showDivider="false">
      <v-container :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <div class="cms_bonus_content text-left" style="width:100%">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <v-img :src="promoData.promo_img" :aspect-ratio="588 / 218"></v-img>
            </v-col>
            <v-col cols="12" class="mt-4">
              <p class="font-weight-bold" style="font-size: larger">{{ promoData.title }}</p>
            </v-col>
            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
              <v-btn
                v-if="isAllowApply"
                class="mb-4 full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light"
                style="width:100%"
                @click="onApplyBonusClick(this.selectedBonusObj)"
                :disabled="isDisableBonusButton"
              >
                {{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="10" lg="10" sm="10">
              <p class="font-weight-bold mb-0">{{ $t(`label.promotionPeriod`) }}:</p>
              <p>{{ promoData.start_time | utcToLocalTime }} - {{ promoData.end_time | utcToLocalTime }}</p>
            </v-col>
            <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp && selectedBonusObj && selectedBonusObj.has_apply_button">
              <v-btn class="full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light" @click="onApplyBonusClick(selectedBonusObj)" :disabled="isDisableBonusButton">
                {{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4">
            <v-col cols="12" v-html="promoHtml" class="cms_bonus_content text-left"></v-col>
          </v-row>
        </div>
      </v-container>
    </newapp-dialog>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
import { BONUS_ANON_LIST, BONUS_LIST, BONUS_MANUAL_BONUS_LIST, BONUS_APPLY_BONUS } from '@/store/bonus.module'
import { CACHE_KEY } from '@/constants/constants'
import axios from 'axios'
import NavFilter from '@/assets/svg/navfilter.svg'
import { SHARED } from '../../constants/constants'
import { CMS_BONUS_ACTIVE } from '@/store/cms.module'
import { BONUS_RESET_APPLY_BONUS_RESPONSE_STORE } from '../../store/bonus.module'
import { errorCodeHelper } from '../../util'
import NewappDialog from '@/components/newDialog.vue'

export default {
  name: 'promotion',
  props: {
    selectedType: {
      type: String,
      default: ''
    },
    cmsBonusCategoryId: {
      type: Number,
      default: 0
    }
  },
  components: {
    NewappDialog,
    NavFilter
  },
  data: () => ({
    wordLimit: 17,
    selectedPromoCategory: '',
    promoHtml: '',
    promoData: {},
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    promotionFilterSheet: false,
    isAllowApply: false,
    currentLanguage: uiHelper.getLanguage(),
    selectedBonusId: '',
    selectedBonusObj: null,
    promotionFound: false,
    promotionTncUrl: '',
    promotionDialog: {
      show: false,
      title: locale.getMessage(`label.promotion`),
      dialogXButton: function() {}
    },
    selectedGameName: '',
    selectedCategory: 'all',
    gameProvider: '',
    gameName: '',
    promoType: '',
    filteredSlotGame: [],
    searchCriteria: {
      bonusCategoryId: 0,
      pageNumber: 1,
      pageSize: 20
    },
    manualPromotionList: [],
    promotionList: [],

    // To prevent spam click
    isDisableBonusButton: false
  }),
  computed: {
    translatedBonusCategories() {
      let categories = this.$store.state.cms.cmsBonusCategories
      return categories.map(c => {
        let targetLanguage = this.currentLanguage.indexOf('en') != -1 ? 'en' : this.currentLanguage

        const localizedText = c.localization.filter(l => l.hasOwnProperty(targetLanguage))[0]
        if (localizedText) {
          return {
            name: localizedText[targetLanguage],
            id: c.id
          }
        }
        return {
          name: c.name,
          id: c.id
        }
      })
    },
    bonusApplyResult() {
      return this.$store.state.bonus.applyBonusResponse.complete
    },
    cmsBonusCategories() {
      return this.$store.state.cms.cmsBonusCategories
    },
    bonusContent() {
      return this.$store.state.cms.bonusContent
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    bonusAnonList() {
      return this.$store.state.bonus.bonusAnonList
    },
    bonusList() {
      return this.$store.state.bonus.bonusList
    },
    manualBonusList() {
      return this.$store.state.bonus.manualBonusList
    }
  },
  watch: {
    async isLoggedIn() {
      if (this.isLoggedIn) {
        await this.getAvailableBonus()
      } else {
        await this.getAnonBonus()
      }
      this.populateDisplayBonusList()
    },
    selectedType() {
      this.searchCriteria.gameType = this.selectedType
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    async bonusAnonList() {
      this.populateDisplayBonusList()
      return this.$store.state.bonus.bonusAnonList
    },
    async bonusList() {
      this.populateDisplayBonusList()
      return this.$store.state.bonus.bonusList
    },
    manualBonusList() {
      return this.$store.state.bonus.manualBonusList
    },
    bonusApplyResult() {
      if (this.$store.state.bonus.applyBonusResponse.complete) {
        // Re-enable apply bonus button
        this.isDisableBonusButton = false
        this.closePromotionTncDialog()
        if (this.$store.state.bonus.applyBonusResponse.success) {
          this.$parent.openAlert(true, locale.getMessage(`message.bonusApplied`))
        } else {
          if (this.$store.state.bonus.applyBonusResponse.code == '5.16') {
            this.$parent.openAlert(true, locale.getMessage(`message.bonusApplied`))
          } else {
            this.$parent.openAlert(false, locale.getMessage(`message.promotionBonusFailed`))
          }
        }
        this.selectedBonusObj = null
        this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
        this.getCmsBonus(this.searchCriteria.bonusCategoryId)
      }
    }
  },
  async created() {
    console.log('promo created')
    this.getPreopenCondition()
    this.metaTag()
    if (this.$route.params.tab === 'vipPromotion') {
      //const tabId = this.$route.params.tab === 'vipPromotion' ? 13 : 0
      //const selectedCategory = this.translatedBonusCategories.find(category => category.id === tabId)
      this.getCmsBonus(13, 'VIP')
    } else {
      this.getCmsBonus(0)
    }
  },
  directives: {
    truncate: {
      inserted(el, binding) {
        const lineLimit = binding.value || 1 // Default to 1 line if not provided
        const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight)
        const maxHeight = lineLimit * lineHeight

        el.style.overflow = 'hidden'
        el.style.display = '-webkit-box'
        el.style.webkitBoxOrient = 'vertical'
        el.style.webkitLineClamp = lineLimit
        el.style.textOverflow = 'ellipsis'
        el.style.whiteSpace = 'normal' // Ensure text wraps normally
        el.style.maxHeight = `${maxHeight}px`

        // Check if content overflows and append ellipsis if necessary
        while (el.scrollHeight > maxHeight && el.textContent.split(' ').length > 1) {
          el.textContent = el.textContent.replace(/\W*\s(\S)*$/, '...')
        }
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      // uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    populateDisplayBonusList() {
      this.promotionList = []
      if (this.isLoggedIn) {
        this.bonusList.forEach(bonus => {
          this.promotionList.push(bonus)
        })

        this.promotionList.sort(function(x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function(x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })

        this.manualBonusList.forEach(bonus => {
          this.promotionList.push(bonus)
        })
        this.promotionList.sort(function(x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function(x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })
      } else {
        this.bonusAnonList.forEach(bonus => {
          this.promotionList.push(bonus)
        })

        this.promotionList.sort(function(x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function(x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })

        this.manualBonusList.forEach(bonus => {
          this.promotionList.push(bonus)
        })
        this.promotionList.sort(function(x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function(x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })
      }
    },
    getPreopenCondition() {
      let bonusId = this.$route.params.bonusId

      if (bonusId != null && bonusId != undefined) {
        this.openPromotionTnc(bonusId)
      }
    },
    selectFilterType(filterType) {
      this.$emit('updateFilterType', filterType)
      this.promotionFilterSheet = false
    },
    async getManualPromotion() {
      await this.$store.dispatch(`${BONUS_MANUAL_BONUS_LIST}`)
    },
    getBonusTitle(bonusLocale) {
      if (bonusLocale.find(x => x.language == this.currentLanguage) != null && bonusLocale.find(x => x.language == this.currentLanguage) != undefined) {
        return bonusLocale.find(x => x.language == this.currentLanguage).title
      } else if (bonusLocale.find(x => x.language == 'en') != null && bonusLocale.find(x => x.language == 'en') != undefined) {
        return bonusLocale.find(x => x.language == 'en').title
      } else {
        return bonusLocale[0].title
      }
    },
    getBonusDesc(bonusLocale) {
      if (bonusLocale.find(x => x.language == this.currentLanguage) != null && bonusLocale.find(x => x.language == this.currentLanguage) != undefined) {
        return bonusLocale.find(x => x.language == this.currentLanguage).desc
      } else if (bonusLocale.find(x => x.language == 'en') != null && bonusLocale.find(x => x.language == 'en') != undefined) {
        return bonusLocale.find(x => x.language == 'en').desc
      } else {
        return bonusLocale[0].desc
      }
    },
    async getPromotionBonus() {
      if (this.isLoggedIn) {
        await this.getAvailableBonus()
      } else {
        await this.getAnonBonus()
      }
      await this.getManualPromotion()
      this.populateDisplayBonusList()
    },
    getCmsBonus(categoryId, translatedName) {
      console.log(categoryId + translatedName)
      this.selectedPromoCategory = categoryId == 0 ? locale.getMessage('label.all') : translatedName
      this.searchCriteria.bonusCategoryId = categoryId
      let obj = {
        language: uiHelper.getLanguage(),
        cmsBonusCategoryId: categoryId,
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${CMS_BONUS_ACTIVE}`, { obj })
      let type = 'cricket'
      switch (this.searchCriteria.bonusCategoryId) {
        case 8:
          type = 'sb'
          break
        case 9:
          type = 'ld'
          break
        case 10:
          type = 'rng'
          break
        case 11:
          type = 'table'
          break
        case 12:
          type = 'lottery'
          break
        case 13:
          type = 'vip'
          break
        default:
          break
      }
      this.promoType = type
    },
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getAnonBonus() {
      this.$store.dispatch(`${BONUS_ANON_LIST}`)
    },
    getPromotionTncFile(id, content) {
      try {
        this.$ga.event('Bonus', `View Bonus`, `Bonus ID - ${id}`)
      } catch (err) {
        console.log(err)
      }
      let timestamp = new Date().getTime()
      let fileUrl = `${`${this.mediaUrl}/html/promotion/${this.currentLanguage}/${id}.html?v=${timestamp}`}`
      let promotionNotFoundUrl = `${process.env.BASE_URL}${`static/html/promotion/${this.currentLanguage}/non-found.html`}`
      this.promotionDialog.dialogXButton = this.closePromotionTncDialog

      let language = uiHelper.getLanguage()

      switch (language) {
        case 'enin':
          language = 'en'
          break
        case 'enne':
          language = 'en'
          break
        default:
          break
      }
      axios({
        method: 'GET',
        url: content,
        responseType: 'json'
      })
        .then(response => {
          console.log(response.data) // Logging the response data here
          if (response.status == 200) {
            this.promoHtml = response.data['tnc'].find(x => x.language == language)['content'].replace(/&nbsp;/g, ' ')
            this.promoData = {
              title: response.data.localization[0].title,
              start_time: response.data.start_time,
              end_time: response.data.end_time,
              promo_img: response.data.res_img_source[0].resource.mobile.imageurl
            }
            this.promotionDialog.show = true
          }
        })
        .catch(() => {
          this.promotionTncUrl = promotionNotFoundUrl
          this.promotionDialog.show = true
          console.log(`Promotion ID : ${id} `)
        })
    },
    onApplyBonusClick(bonus) {
      if (!this.isLoggedIn) {
        this.promptLogin()
        return
      }

      // Prevent spam click, disable button first before fire API
      this.isDisableBonusButton = true
      if (this.applyBonusTimer) {
        clearTimeout(this.applyBonusTimer)
      }
      this.applyBonusTimer = setTimeout(() => {
        this.applyBonus(bonus)
      }, 50)
    },
    applyBonus(bonus) {
      this.selectedBonusObj = bonus
      if (this.selectedBonusObj.redirect_to_deposit) {
        this.applyInDepositPage()
        return
      } else {
        this.onConfirmApplyOk()
        return
      }
      //this.openPromotionTnc(bonus)
    },
    applyInDepositPage() {
      let selectedBonusId = this.selectedBonusObj.bonus_id
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.DEPOSIT,
          params: {
            bonusId: selectedBonusId,
            lang: this.$route.params.lang
          }
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT,
          params: {
            bonusId: selectedBonusId,
            lang: this.$route.params.lang
          }
        })
      }
    },
    promptLogin() {
      this.$parent.goToLoginPage()
    },
    openPromotionTnc(bonus) {
      if (bonus != null) {
        this.selectedBonusObj = bonus
        this.isAllowApply = bonus != null
        this.getPromotionTncFile(bonus.id, bonus.content)
      }
    },
    closePromotionTncDialog() {
      this.promotionTncUrl = ''
      this.promotionDialog.show = false
      this.selectedBonusObj = null
    },
    onConfirmApplyOk() {
      const applyBonusObj = {
        bonusId: this.selectedBonusObj.bonus_id
      }
      this.$store.dispatch(`${BONUS_APPLY_BONUS}`, { applyBonusObj })
    },
    confirmationDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.Confirmation`)
      dialog.message.push(locale.getMessage(`label.confirmApplyPromotion`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.onConfirmApplyOk()
      })
      dialog.button.push({
        title: locale.getMessage(`button.cancel`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    }
  }
}
</script>

<style lang="scss">
.padding-desktop-promotion {
  padding: 1% 12%;
}
.page-promotion-button {
  text-transform: capitalize !important;
  border-radius: 32px;

  label {
    cursor: pointer;
  }

  &:hover {
    background-color: #ffce01 !important;
    color: #000000 !important;
    border-radius: 32px;
  }
}
.desktop_promo {
  padding-left: 12%;
  padding-right: 12%;
}
.conditional-styles {
  font-weight: bold;
  background-color: #ffce01 !important;
  color: #000000 !important;
  border-radius: 32px;
}
.promotion-menu {
  gap: 10px;
}
.promotion-box {
  flex-direction: row;
  background-color: #f3f5f6 !important;
  border-radius: 12px;
}
.mobile-detail-btn {
  height: 40px !important;
  background-color: #0080f6 !important;
  font-family: 'Roboto', sans-serif;
  color: inherit;
}
.mobile-apply-btn {
  height: 40px !important;
  background-color: inherit;
  font-family: 'Roboto', sans-serif;
  color: inherit;
}
.mobile-header {
  background-color: #000000;
  color: var(--v-newMobilePrimary-base) !important;
  margin-top: auto;
  margin-bottom: auto;
}
.mobile-category-menuBtn {
  justify-content: space-between;
  background-color: #f5f5f5;
}

.mobile-menu-card-wrapper {
  border-radius: 15px;
  margin: 15px 15px 10px 15px;
}

.bonus-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.bonus-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.bonus-category-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;
}

.bonus-category-menu {
  //max-height: 80px;
  border-radius: 12px !important;
  background-color: #f3f5f6 !important;

  .bonus-category-menu-title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
    text-transform: capitalize;

    &:hover {
      color: #000000;
    }
  }

  .bonus-category-menu-divider {
    border: 2px solid #fddd04;
  }
}

.promotion-card-container {
  border-radius: 20px !important;

  .promotion-box {
    border-radius: 12px !important;
    background-color: #f3f5f6 !important;

    .promotion-image-box {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .promotion-desc-box {
      background-color: #f3f5f6;
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 0px !important;
      height: 100%;
      font-size: 16px;
      padding-top: 24px;

      .promotion-desc-title {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;

        .promotion-menu-desc {
          font-family: 'Roboto', sans-serif;
        }
      }
    }
  }
}

.cms_bonus_content {
  font-family: 'Heebo-Medium';
  text-align: left;
  padding-bottom: 24px;
}

.cms_bonus_content table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.promotion-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
  margin-top: 0;
}

.promotion-content {
  margin-bottom: 0;
  font-size: 16px;
  margin-top: 0;
}

table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #fff;
}

table thead tr td {
  font-size: 16px;
}

table tbody tr th {
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
}

table td {
  padding: 5px;
}

.cms_bonus_content {
  max-height: 450px;

  table,
  th,
  td {
    border: 1px solid #000000;
  }

  table thead th {
    background-color: #000000;
    font-size: 16px;
    color: #ffffff;
  }

  table tbody tr th {
    font-size: 16px;
  }

  table td {
    padding: 5px;
  }
}

.maxFrameHeight {
  max-height: 100%;
}

.promotion-filter-display {
  padding: 10px 15px;
}

.promotion-filter-display-text {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.promotion-filter-button {
  background: linear-gradient(180deg, #273270 0%, #1c244f 100%);
  border-radius: 5px;
  width: 90px !important;
  font-weight: bold;
  min-width: 90px !important;
  padding: 5px 0px !important;

  .v-icon {
    font-size: 28px;
    padding: 0px;
  }
}

@media (max-width: 1350px) {
  .bonus-category-menu {
    border-radius: 12px !important;
    background-color: #f3f5f6 !important;

    .bonus-category-menu-title {
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      text-transform: capitalize;
    }
  }
}

@media (max-width: 1150px) {
  .bonus-category-menu {
    border-radius: 12px !important;
    background-color: #f3f5f6 !important;

    .bonus-category-menu-title {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .mobile-detail-btn {
    font-size: 13px !important;
  }
  .mobile-apply-btn {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .bonus-category-menu {
    border-radius: 12px !important;
    background-color: #f3f5f6 !important;

    .bonus-category-menu-title {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      text-transform: capitalize;

      &:hover {
        color: #000000;
      }
    }

    .bonus-category-menu-divider {
      border: 2px solid #fddd04;
    }
  }
  .mobile-apply-btn {
    font-size: 11px !important;
  }
  .mobile-detail-btn {
    font-size: 11px !important;
  }
  .promotion-card-container {
    border-radius: 20px !important;

    .promotion-box {
      border-radius: 12px !important;
      background-color: #f3f5f6 !important;

      .promotion-image-box {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      .promotion-desc-box {
        background-color: #f3f5f6;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 0px !important;
        height: 100%;
        font-size: 16px;
        padding-top: 15px;

        .promotion-desc-title {
          font-family: 'Roboto', sans-serif;
          font-size: 18px;

          .promotion-menu-desc {
            font-family: 'Roboto', sans-serif;
          }
        }
      }
    }
  }
}

@media (max-width: 959px) {
  .promotion-box {
    flex-direction: column !important;
    background-color: #f3f5f6 !important;
    border-radius: 12px;
  }
  .promotion-desc-box {
    background-color: #333333 !important;
    color: white;
    .mobile-detail-btn {
      font-family: 'Roboto', sans-serif;
      background-color: #0080f6 !important;
      color: white !important;
    }
    .mobile-apply-btn {
      font-family: 'Roboto', sans-serif;
      background-color: var(--v-newMobilePrimary-base) !important;
      color: black !important;
    }
  }

  .bonus-category-menu {
    border-radius: unset !important;
    background-color: #f3f5f6 !important;

    .bonus-category-menu-title {
      font-family: 'Roboto', sans-serif !important;
      font-size: 16px;
      text-transform: capitalize;

      &:hover {
        color: #000000;
      }
    }

    .bonus-category-menu-divider {
      border: 2px solid #fddd04;
    }
  }
  .promotion-card-container {
    border-radius: unset !important;

    .promotion-box {
      border-radius: 12px !important;
      background-color: #f3f5f6 !important;

      .promotion-image-box {
        border-top-left-radius: unset !important;
        border-bottom-left-radius: unset !important;
      }

      .promotion-desc-box {
        background-color: #f3f5f6;
        border-top-right-radius: unset !important;
        border-bottom-right-radius: unset !important;
        height: 100%;
        font-size: 16px;
        padding: 15px 10px;

        .promotion-desc-title {
          font-family: 'Roboto', sans-serif;
          font-size: 20px;

          .promotion-menu-desc {
            font-family: 'Roboto', sans-serif;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .cms_bonus_content {
    font-size: 12px !important;
  }
  .bonus-category-menu {
    border-radius: 20px !important;

    .bonus-category-menu-title {
      font-size: 12px;
      text-transform: capitalize;

      &:hover {
        color: #0c2aab;
      }
    }

    .bonus-category-menu-divider {
      border: 2px solid #fddd04;
    }
  }

  .promotion-card-container {
    border-radius: unset !important;

    .promotion-box {
      border-radius: 10px !important;
      .promotion-desc-box {
        background-color: #ebebeb;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        height: 100%;
        font-size: 12px;
        line-height: 1rem;
        padding: 10px 5px;

        .promotion-desc-title {
          font-size: 15px;
          line-height: 1rem;
        }

        .v-btn.primary-button {
          width: 100% !important;
          height: fit-content !important;
          font-size: 11px !important;
          min-width: 50px !important;
        }
      }
    }
  }
}
</style>
